.SingleProject {
  top: 0;
  width: 100%;
  position: absolute;
  overflow: hidden;
  background-color: rgba(213, 255, 255, 0.182);
  margin: 0 auto;
  margin-left: auto;
  margin-right: auto;
  padding: 0 65px 0 65px;

  .singleProjectMenu {
    position: fixed;
    opacity: 0;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    width: auto;
    left: 40px;
    margin-top: 90px;
    padding-right: 5px;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: left;
    z-index: 1;
    transition: 0.2s;
    animation: cuteFadeOut 3s;
    line-height: 0;
    background: #F8FFFF;


    .projMenuButton {
      // cursor: pointer;
      line-height: 0;
    }

  }

  .singleProjectMenu:hover {
    opacity: 1;
  }

  .singleProjectMenu:focus {
    opacity: 1;
  }

  .singleProjectMenu:active {
    opacity: 1;
  }

  @keyframes cuteFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes cuteFadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .projMenuButton {
    padding: 0;
    border: none;
    background: none;
    text-align: left;
    line-height: normal;
  }

  .projMenuText:nth-child(2) {
    display: block;
    opacity: 1;
    color: black;
  }

  .projMenuButton:hover {
    outline: none;
  }

  .projMenuButton:focus {
    outline: none;
  }

  .title-box-single {
    margin-top: 250px;
    margin-bottom: 150px;
    align-items: center;
    text-align: center;
  }

  .titleSingle {
    text-transform: uppercase;
    font-size: 105px;
    font-weight: bold;
    width: auto;
    height: auto;
    line-height: 1.5em;
  }

  .descSingle {
    margin-top: -40px;
    margin-bottom: 50px;
    font-size: 18px;
  }

  .intro {
    margin: auto;
    width: 59%;
    text-align: center;
  }

  .mainImage {
    margin-top: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }

  .solution-thing {
    padding-top: 120px;
    padding-bottom: 100px;
  }

  .solution-div {
    padding-top: 100px;
  }

  .solution-title {
    padding-left: 120px;
  }

  .alice-carousel {
    padding: 40px;
    margin: 0 auto;
    direction: ltr;
    margin-right: auto;
    margin-left: auto;

    .alice-carousel__wrapper {
      width: 100%;
      text-align: center;
    }

    .alice-carousel__prev-btn-item, .alice-carousel__next-btn-item {
      display: none;
    }
  }

  .solution-text {
    width: 55%;
    padding-left: 120px;
    text-align: left;
  }

  .solutionValue {
    padding-top: 20px;
    padding-bottom: 30px;
    margin: auto;
    width: 60%;
    text-align: center;
  }

  .process {
    padding-top: 100px;
  }

  .role-thing {
    padding-top: 50px;
    margin-bottom: 80px;
    margin-left: 50px;
  }

  .role-text {
    width: 40%;
    margin-right: 155px;
  }

  .grid-container {
    margin-top: 25px;
    margin-bottom: 125px;
    display: grid;
    grid-template-columns: auto auto;
    background-color: none;
  }
  .grid-item {
    padding: 10px;
  }

}


p {
  line-height: 1.4;
}

.footer {
  opacity: 0;
}

.role-div {
  display: flex;
  flex-direction: row;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
}

.initiated-slideshow {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1.3em;
  flex-basis: 100%;
  width: 100%;
}

.initiated {
  text-align: center;
  font-size: 12px;
  margin-bottom: 50px;
}

.modernaMovie {
  margin: 0 auto;
  display: block;
}

.process-title {
  margin-left: 50px;
}

.process-text {
  width: 750px;
  margin-left: 50px;
}

.img1 {
  width: 200px; /* width of container */
  height: 200px; /* height of container */
  object-fit: contain;
  border: 5px solid black;
}

.sImg1 {
  max-width: 350px;
  max-height: 500px;
  object-fit: contain;
}

.sVImg1 {
  max-width: 250px;
  max-height: 350px;
  object-fit: contain;
}

.pImg1 {
  max-width: 450px;
  max-height: 350px;
  object-fit: contain;
}

.top {
  display: flex;
  justify-content: center;
  column-gap: 7.5px;
  margin-bottom: 50px;
  color: black;
  width: 100%;
  height: 50px;

  .top-button {
    padding: 0;
    border: none;
    background: none;
  }
}

.arrowUp {
  font-size: 44px;
  margin-bottom: 55px;
  right: 30px;
  bottom: 20px;
  height: 40px;
  
  position: fixed;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  .SingleProject {
    padding: unset;
  }

  .singleProjectMenu {
    flex-direction: row !important;
    width: 100% !important;
    position: sticky !important;
    color: black !important;
    animation: none !important;
    top: 0;
    gap: 0px !important;

    padding-top: 40px !important;

    .projMenuButton {
      color: black !important;
      opacity: 1 !important;
    }

    .projMenuButton:first-child {
      padding-left: 20px;
      margin: unset !important;

    }

    .projMenuButton:last-child {
      padding-right: 20px;
    }
  }

  .singleProjectMenu {
    opacity: 1 !important;
  }

  .descSingle {
    margin-top: 15px !important;
    padding-left: 20px;
    padding-right: 20px;
  }

  .title-box-single h1 {
    height: 50px !important;
  }

  .title-box-single h1 {
    font-size: 30px !important;
  }
  .intro {
    width: 100% !important;
    padding-left: 20px;
    padding-right: 20px;
  }
  .intro p {
    text-align: center;
  }

  iframe {
    width: 80%;
  }

  .solution-thing {
    all: unset !important;
  }

  .solution-title, .process-title {
    padding-left: unset !important;
    text-align: center !important;
    margin-top: 100px; 
    margin-bottom: 40px;
  }

  .solution-text {
    text-align: center !important;
    width: 100% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-bottom: 40px !important;
  }

  #carousel-container {
    all: unset !important;
  }

  .solutionValue {
    width: 100% !important;
    line-height: 1.2;
    padding: 20px;
    margin-top: 50px !important;
  }

  .BrainhubCarouselItem {
    width: 330px !important;
  }

  .process {
    padding-top: unset;
  }

  .process-text {
    padding-top: 40px;
    margin-left: unset;
  }

  .process-title, .process-text {
    width: 330px !important;
    text-align: center !important;
    margin: 0 auto;
    line-height: 1.2;
  }

  .role-thing {
    margin-left: unset !important;

    padding-left: 20px !important;
    padding-right: 20px !important;

    text-align: center;
  }

  .role-div {
    flex-direction: column;
  }
  
  .role-text {
    width: 100% !important;
  }

  .role-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .initiated {
    line-height: 2;
    max-width: 350px;
    justify-content: center;
    margin: 0 auto;
    padding-top: 20px;
  }

}