.About {
  top: 0;
  width: 100%;
  min-height: 100%;
  position: absolute;
  overflow: hidden;
  background-color: #f9f9f9;
}

.aboutTitle {
  margin-top: 100px;
  margin-left: 40px;
  margin-bottom: 40px;
  // text-transform: uppercase;
}

.content {
  margin-left: 40px;
}

.some-page-wrapper {
  // margin: 15px;
  // background-color: red;
  font-size: 12px;
  // margin-bottom: 400px;
}

.content {
  width: 80%;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 65%;
  column-gap: 2.5em;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  // padding-right: 55px;
  margin-top: 30px;
}

.blue-column {
  // background-color: blue;
  height: 100px;
  line-height: 20px;
}

.green-column {
  // background-color: green;
  height: 100px;
  line-height: 20px;
}

.yellow-column {
  // background-color: green;
  height: 100px;
  line-height: 20px;
}

.aboutText {
  // margin-left: 20px;
  margin-top: 5%;
  line-height: 1.3;
  // align-self: stretch;
  width: 58%;
  text-indent: 0px;
  // margin-right: 27px;
  // font-size: 20px;
  font-size: 20px;
  // margin-bottom: 100px;
  text-transform: uppercase;
  // white-space: break-spaces;
}

.aboutText2 {
  // margin-top: -23%;
  white-space: break-spaces;
  max-width: 620px;
  font-size: 15px;
  text-align: left;

  font-family: Arial, Helvetica, sans-serif;
  // margin-bottom: 300px;
}

.aboutText3 {
  // width: 100%;
  white-space: break-spaces;

  text-align: left;
  margin-top: 20%;

  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 300px;
}

.bigStuff {
  font-size: 40px;
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
}

@keyframes thorby {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

.thorby {
  position: absolute;
  margin-top: -25.5%;
  margin-left: 65%;
  animation: thorby 0.4s ease-in-out;

  // top: 265px;
  #thorben {
    width: 250px;
    height: auto;
    // align-self: right;
  }
  // padding-bottom: 30%;
}

// .buttons {
//     display: flex;
//     margin-top: 80px;
//     align-items: right;
//     justify-content: right;

//     button {font-weight: 500;}
//     a {text-decoration: none;
//         color: white;}
// }

.doubleLines {
  margin-top: 8%;
  text-transform: uppercase;
  font-size: 80px;
  min-width: 350%;
  width: auto;
  // overflow-y: hidden;
  white-space: nowrap;
  line-height: 1;
  p {
    font-family: "Whyte";
  }
  // transform: translateX(100%);
  animation: scroll-left 15s linear infinite;

  // .line1 {
  //   margin-left: -5%;
  //   // transform: translateX(100%);
  //   // animation: scroll-left 20s linear infinite;
  // }
}

@keyframes scroll-left {
  0% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.content2 {
  position: relative;
  margin-top: -3%;
  top: 0px;
  margin-left: 50%;
  width: 45%;
}

.view {
  border: 2px solid #ffadac;
  position: absolute;
  top: 80px;
  left: -70%;
  width: 130px;
  height: 70px;
  // padding-top: 5px;
  font-size: 14px;
  // border-radius: 50%;
  text-align: center;
  z-index: 500;
  background-color: transparent;
  // cursor: pointer;
  vertical-align: middle;

  p {
    font-family: "Whyte";
  }
}

.view:hover {
  background-color: #febebd;
  border-color: rgb(59, 59, 59);
}

.aboutText4 {
  width: 100%;
  margin-left: -6.5%;
  display: flex;
  margin-top: -40%;
  justify-content: space-between;
  line-height: 1.8;
  padding-bottom: 80px;
}

.aboutText4 ul {
  font-family: "Helvetica";
}

.aboutText4 li {
  font-family: "Helvetica";
  list-style: inside;
  margin-left: 5px;
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .About, .content, .aboutText, .aboutText2 {
    text-align: justify !important;
  }

  .aboutText2 {
    margin-top: 250px;
  }

  .content, .aboutTitle {
    margin-left: 32px !important;
  }

  .aboutTitle {
    padding-top: 30px;
  }

  .aboutText, .aboutText2, .content2 {
    width: 100% !important;
    text-align: justify !important;
  }

  #two {
    width: 48%;
  }

  .aboutText3 {
    width: 80%;
    text-align: justify !important;
  }

  .aboutText4 {
    padding-top: 10px;
    margin-bottom: 20px;
    display: block;
    text-align: justify !important;
  }

  .view {
    position: initial;
    margin-bottom: -788px;
    margin-left: 23%;
    color: black !important;
    p {
      color: black !important;
    }
  }

  .content2 {
    margin-left: 10%;
    margin-top:-35px;
  }

  .doubleLines {
    padding-top: 20px;
    font-size: 50px;
  }

  .thorby {
    position: absolute !important;
    top: 18% !important;
    left: 50%;
    // margin-left: -250px;
    // left: 53% !important;
    margin-top: 25px !important;
    margin-left: -80px !important;
  }

  #thorben {
    width: 150px !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
