.header {
    // top: 10px;
    width: 100%;
    // height: 80px;
    padding-top: 30px;
    overflow: auto;
    padding-left: 15px;
    padding-right: 15px;
    background: transparent;
    position: fixed;

    .headerWrapper {
        width: 100%;
        z-index: 100;
    }

    .tabs {
        z-index: 100;
        position: absolute;
        right: 0px;
        padding: 10px;
        height: auto;
        text-transform: lowercase;
        text-decoration: underline;
        
        a {
            font-size: 15px;
            color: black;
            text-decoration: none;    
            padding: 30px 30px 0px 0px;
            color: black;
            height: 40px;
        }

    }
    

    .menu {
         width: 100px;
         

        .logo {

            // position: fixed;
            z-index: 100;
            height: 90px;
            margin-left: 0px;
            margin-top: -40px;

            &:hover {
            
            }
        }
    }
}


@media only screen and (max-width: 600px) {
    .header {
        padding-left: unset;
        padding-right: unset;
        border-bottom: 1px solid black;
    }

    .tabs {
        a {
            height: 30px !important;
            padding: 30px 15px 0px 0px !important;
        }
    }
}