* {
  box-sizing: border-box;
}

.Home {
  height: 275vh;
  min-height: auto;
  top: 0;
  background-color: #e6fdfe;
  z-index: -1;
  width: 100%;
  position: absolute;
  overflow: hidden;
}


.Head {
  opacity: 0;
  pointer-events: none;
}


.banner {
  opacity: 0;
}
.bannerBgAddition {
  opacity: 0;
}

#homeBigText {
  margin-top: 20%;
  margin-left: 20px;
  margin-right: 10px;
  font-size: 65px;
}

#bigText {
  opacity: 0;
  margin-left: 10px;
}

.element-i,
.element-p,
.element-c,
.element-ch,
.element-s {
  opacity: 1;
}

.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 100%;
  vertical-align: middle;
  overflow: hidden;
}

#mySVG {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
}


.cta {
  border: 2px solid #ffadac;
  position: absolute;
  right: 10%;
  bottom: 19%;
  width: 130px;
  height: 70px;
  // padding-top: 5px;
  font-size: 14px;
  // border-radius: 50%;
  text-align: center;
  z-index: 99999999999999 !important;
  background-color: transparent;

  p {
    font-family: "Whyte";
  }
}

.cta:hover {
  background-color: #febebd;
  border-color: rgb(59, 59, 59);
}

.subtitle {
  font-size: 18px;
  margin-left: 20px;
  margin-top: -115px;
  opacity: 0;
}

.arrow-container {
  text-align: center;
  position: absolute;
  top: 200px;
  width: 100%;
  z-index: 1000;
  opacity: 0;
}

.grid-container {
  display: inline-grid;
  grid-column-gap: 0px;
  grid-template-columns: repeat(3, 1fr);
  padding: 0px;
  list-style-type: none;
  font-size: 30px;
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  margin: 0;

  a {
    text-decoration: none;
    color: black;
  }
}

.grid-item {
  text-align: left;
  border-top: 1px solid black;
  border-right: 1px solid black;
  padding-top: 80px;
  padding-left: 40px;

  padding-right: 40px;
  font-size: medium;
  font-weight: 100px;
  line-height: 25px;
  height: 330px;
  font-family: Arial, Helvetica, sans-serif;

  &:hover {
    background-color: #f1f0f0;
  }
}

.slogan {
  font-size: 14px;
  display: inline-block;
  position: absolute;
  right: 20px;
  top: -120px;
  width: 300px;
}

.view-all-box {
  font-size: 22px;
  padding-top: 30px;
  display: block;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  border-bottom: 1px solid #000;
  color: black;
  -webkit-transition: background-color 800ms cubic-bezier(0.25, 0.75, 0.25, 1);
  transition: background-color 800ms cubic-bezier(0.25, 0.75, 0.25, 1);
  height: 50px;

  img {
    width: 28px;
    margin-left: 5px;
  }

  &:hover {
    background-color: #f1f0f0;
  }
}

.title {
  font-family: "Whyte Inktrap";
}

.description {
  display: inline-block;
  padding-top: 20px;
  font-size: 15px;
}

.weird-container {
  width: 100%;
  font-size: 14px;
  gap: 5px;
  display: flex;
  margin-top: 200px;
  height: 200px;

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .Home {
    height: 100vh;
    padding: 10px;
  }

  .Head {
    margin-top: 100px;
    font-size: 12px;
    height: 22vh;
  }

  .svg-container {
    height: 1200px;
    margin-top: -200px;
  }

  #homeBigText {
    font-size: 40px;
    margin-left: unset;
    margin-top: 50%;
    text-align: center;
  }

  .slogan {
    position: absolute;
    width: 100%;
    left: 0px;
    text-align: center;

    margin-top: 600px;
    font-size: 12px;
  }

  .subtitle {
    margin-left: unset;
    padding: 105px 10px 0;
  }

  #triangle {
    display: none;
  }

  .cta {
    position: absolute;
    top: 25%;
    left: 35%;
    z-index: 40000000;
    color: black;
    p {
      color: black !important;
    }
  }

  .weird-container {
    display: none;
  }

  .mobile-tagline {
    display: inline-block !important;
    padding-left: 10px;
    position: absolute;
    top: 50%;
    left: 15%;
    // right: 50%;
    justify-content: center;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
