@media screen and (max-width: 600px) {

  .Projects {
    margin-left: auto;
    margin-right: auto;

    img {
      display: none !important;
    }
  }

  .eachProject {
    width: 95%;
  }

  .titleProjects {
    padding-top: 30px;
    margin-left: 32px !important;
  }

  .title-box {
    padding-top: 20px !important;
    padding-bottom: 10px !important;
  }

  .title {
    font-size: 30px !important;
  }  

  .description {
    opacity: 1 !important;
    line-height: 1.1rem;
    font-size: 12px !important;
    padding-top: 5px !important;
  }
    
  
}

.Projects {
  background-color: #f9f9f9;
  height: auto;
  min-height: 100%;
  top: 0;
  z-index: 0;
  width: 100%;
  position: absolute;
  cursor: none;
}

.titleProjects {
  margin-left: 40px;
  margin-top: 100px;
}

.eachProject {
  margin-top: 60px;
  margin-left: -7px;
  justify-content: stretch;
  font-weight: lighter;
  cursor: none;
  
  .title {
    font-size: 80px;
    font-family: Whyte Inktrap;
    line-height: 1;
  }

}

.title-box:hover {
  .title {
    font-size: 80px;
    font-weight: bolder;
  }
}

li {
  list-style: none;
  cursor: none;
}

a {
  text-decoration: none;
  color: black;
  cursor: none;
}

.banner-image {
  width: 350px;
  height: auto;
  display: none;
}

.eachProject li:hover {

  .banner-image {
    display: inline-block;
    position: absolute;
    z-index: 10000;
    margin-left: 65%;
    margin-top: -7%;
  }
}

.title-box {
  width: 95%;
  border-top: 1px solid black;
  padding: 35px 0px 0px 0px;
}

.title-box:hover {
  span {
    opacity: 1;
  }
}

.description {
  opacity: 0;
}


