.footerWrapper {
    display: flex;
    justify-content: space-between;
    line-height: 2.0;
    bottom: 0;
    position: fixed;
    width: 100%;
    height: auto;
    padding: 10px;
    z-index: 99999999;
    background: red;

    .infoLeft {
        padding-top: 15px;
        padding-left: 20px;
        padding-bottom: 10px;

        a {
            font-size: 15px;
            color: rgb(0, 0, 0);
            text-decoration: none;
        }

    }

    .infoRight {
        padding-top: 15px;
        right: 30px;
        position: absolute;
        padding-bottom: 10px;

        a {
            font-size: 15px;
            font-weight: 100;
            color: rgb(0, 0, 0);
            text-decoration: none;
        }
        
    }
}


@media only screen and (max-width: 600px) {
    .footerWrapper {
        border-top: 1px solid black;
    }

    .infoLeft {
        padding-left: 15px !important;
    }


    .infoRight {
        right: 20px !important;
    }
}