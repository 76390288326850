@font-face {
    font-family: 'Whyte Inktrap';
    src: url(../fonts/WhyteInktrap-Regular.ttf);
}

@font-face {
    font-family: 'Whyte Inktrap Medium';
    src: url(../fonts/WhyteInktrap-Medium.ttf);
}

html,
body {
    overflow-x: hidden;
    margin: 0;
    padding: 0; 
    // background-color: #F7F7F7;
    font-family: 'Whyte Inktrap', sans-serif;
    text-rendering: optimizelegibility;
    font-feature-settings: "kern";
    font-kerning: normal;
}

